<template>
  <div>
    <ValidationObserver ref="observer">
      <b-form @submit.prevent="onSubmit">
        <b-sidebar bg-variant="white" v-model="is" backdrop shadow>
          <template #header>
            <div
              class="d-flex justify-content-between align-items-center px-1 py-50 bg-light"
            >
              <h5 class="m-0">
                <strong>إضافة موظف</strong>
              </h5>
              <b-button
                @click="is = false"
                size="sm"
                variant="flat-secondary"
                class="btn-icon rounded-circle"
              >
                <unicon name="times" width="16" height="16" />
              </b-button>
            </div>
          </template>
          <b-col>
            <BTextInputWithValidation
              rules="required"
              label="الاسم "
              name="الاسم"
              v-model="employeeDto.firstName"
              placeholder="أدخل اسم"
            />
            <BTextInputWithValidation
              rules="required"
              label="الكنية "
              name="الكنية"
              v-model="employeeDto.lastName"
              placeholder="أدخل الكنية"
            />
            <label>صورة الموظف</label>
            <fileDragDrop
              @uploaded="uploadEmployeeImage"
              id="employee-photo"
              height="100px"
              type="image"
              title="صورة جديدة"
            />
            <img
              v-if="!!employeeDto.imageDto.base64"
              :src="employeeDto.imageDto.base64"
              style="max-height: 320px; object-fit: fill"
              class="w-100 mb-1"
            />
            <BTextInputWithValidation
              rules="email"
              class="mt-2"
              label="البريد الالكتروني "
              name="البريد الإلكتروني"
              v-model="employeeDto.email"
              placeholder="أدخل البريد الالكتروني"
            />
            <BTextInputWithValidation
              rules="digits:10|integer"
              label="رقم الجوال "
              name="رقم الجوال"
              v-model="employeeDto.mobileNumber"
              placeholder="أدخل رقم الجوال"
            />
            <BDatepickerWithValidation
              rules="required"
              label="تاريخ الميلاد "
              name="تاريخ الميلاد"
              v-model="employeeDto.dob"
              placeholder="أدخل تاريخ الميلاد"
              :max="new Date()"
            />
            <BTextInputWithValidation
              rules="required"
              label=" المنصب الوظيفي"
              name="المنصب الوظيفي"
              v-model="employeeDto.position"
              placeholder="ادخل المنصب الوظيفي"
            />
          </b-col>
          <template #footer>
            <div class="d-flex p-1 border-top">
              <b-button variant="outline-primary" class="ml-auto mr-1" @click="is = false"
                >إلغاء</b-button
              >
              <b-button variant="primary" type="submit">تم</b-button>
            </div>
          </template>
        </b-sidebar>
      </b-form>
    </ValidationObserver>
  </div>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import BTextInputWithValidation from "@core/components/inputs/BTextInputWithValidation";
import BDatepickerWithValidation from "@core/components/inputs/BDatepickerWithValidation";
import { mapActions } from "vuex";
import fileDragDrop from "@core/components/file-drag-drop/file-drag-drop.vue";

export default {
  components: {
    ValidationObserver,
    BTextInputWithValidation,
    BDatepickerWithValidation,
    fileDragDrop,
  },
  computed: {
    is: {
      get() {
        return this.$store.getters["isCreateEmployeeModal"];
      },
      set(is) {
        this.$store.dispatch("setIsCreateEmployeeModal", is);
      },
    },
  },
  data: () => ({
    employeeDto: {
      id: 0,
      firstName: "",
      lastName: "",
      email: "",
      position: "",
      mobileNumber: "",
      dob: new Date(1970),
      imageDto: {
        id: 0,
        path: "",
        base64: "",
      },
    },
  }),
  methods: {
    ...mapActions(["createEmployee"]),
    onSubmit() {
      this.$refs.observer.validate().then((success) => {
        if (success) {
          this.createEmployee({
            id: this.employeeDto.id,
            firstName: this.employeeDto.firstName,
            lastName: this.employeeDto.lastName,
            email: this.employeeDto.email,
            position: this.employeeDto.position,
            mobileNumber: this.employeeDto.mobileNumber,
            dob: this.employeeDto.dob,
            imageDto: {
              id: 0,
              path: "",
              base64: this.employeeDto.imageDto.base64,
            },
          }).then(() => {
            Object.assign(this.employeeDto, {
              id: 0,
              firstName: "",
              lastName: "",
              email: "",
              position: "",
              mobileNumber: "",
              dob: new Date(1970),
              imageDto: {
                id: 0,
                path: "",
                base64: "",
              },
            });
            requestAnimationFrame(() => {
              this.$refs.observer.reset();
            });
          });
          this.is = false;
        }
      });
    },
    uploadEmployeeImage(file) {
      console.log(file)
        this.employeeDto.imageDto.base64 = file[0].base64;
    },
  },
};
</script>
