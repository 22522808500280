<template>
    <b-col cols="6" class="d-flex justify-content-end">
        <b-input-group class="input-group-merge" style="margin: auto; margin-left: 20px;">
            <b-form-input
                id="global-search"
                type="search"
                placeholder="ابحث عن موظف محدد"
                v-model="employeeFilter.search"
                @input="filterEmployees(1)"
            />
            <b-input-group-append is-text>
                <unicon width="16" height="16" fill="gray" name="search" />
            </b-input-group-append>
        </b-input-group>
         <b-button size="sm" variant="primary" class="text-nowrap" @click="setIsCreateEmployeeModal(true)"> 
            موظف جديد
            <unicon class="ml-1" name="plus" width="18" height="18" fill="#fff" />
        </b-button>
        <createEmplyeeModal />
    </b-col>
</template>
<script>
import createEmplyeeModal from "./create-emplyee-modal";
import {
    BButton,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput
} from "bootstrap-vue";
import { mapActions, mapGetters } from 'vuex';

export default {
    components: {
        BButton,
        BCol,
        BInputGroup,
        BInputGroupAppend,
        BFormInput,

        createEmplyeeModal
    },
    computed: {
        ...mapGetters(["employeeFilter"])
    },
    methods: {
        ...mapActions(['setIsCreateEmployeeModal', 'filterEmployees'])
    }
}
</script>